<template>
  <div>
    <dashboard-page-title :showAddBtn="false">{{ title }}</dashboard-page-title>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col md="12" class="mb-3">
            <input-form
              :placeholder="$t('main.name')"
              :label="$t('main.name')"
              :name="$t('main.name')"
              validate="required"
              v-model="item.name"
              :disabled="disabled"
            />
          </b-col>
          <b-col md="12" class="mb-3">
            <input-form
              :placeholder="$t('shippingCompanies.refNumber')"
              :label="$t('shippingCompanies.refNumber')"
              :name="$t('shippingCompanies.refNumber')"
              validate="required"
              v-model="item.ref_id"
              :disabled="disabled"
            />
          </b-col>

          <b-col cols="12" v-if="!disabled">
            <b-button variant="primary" type="submit" :disabled="loadingSubmit">
              <span v-if="!loadingSubmit">{{ $t("main.save") }}</span>
              <template v-else>
                <spinner-loading class="d-inline"></spinner-loading>
                <span>{{ $t("main.loading") }}</span>
              </template>
            </b-button>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import companyServices from "../services";
export default {
  props: { id: { type: [String, Number], default: "" } },
  data() {
    return {
      pageName: this.$route?.meta?.name || "",
      title: this.$t(this.$route?.meta?.title),
      loadingSubmit: false,
      item: {
        name: "",
        ref_id: "",
      },
    };
  },
  computed: {
    disabled() {
      switch (this.pageName) {
        case "company.view":
          return true;
        default:
          return false;
      }
    },
  },
  methods: {
    async getItem() {
      if (!this.id) return;
      const { data } = await companyServices.getShippingCompany(this.id);
      this.item = data;
    },
    async onSubmit() {
      this.loadingSubmit = true;
      try {
        if (this.id) {
          await companyServices.updateShippingCompany(this.id, this.item);
          this.$router.push({ name: "shipping-companies-list" });
        } else {
          await companyServices.createShippingCompany(this.item);
          this.$router.push({ name: "shipping-companies-list" });
        }
      } catch (error) {
        console.log(error);
      }
      this.loadingSubmit = false;
    },
  },
  created() {
    this.getItem();
  },
};
</script>
